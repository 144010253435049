import React, { useCallback, useEffect, useState } from 'react';
import * as S from './styled';
import { useSignupContext } from '../../../hooks';
import { Alert, DataCard } from '../../../components';
import { OriginateFormGenerator } from '@indicina1/originate-form-builder';
import theme from '../../../stylesheets/theme';
import { useMutation } from '@apollo/client';
import { UPDATE_CUSTOMER_INFO } from '../mutations';
import { generateCardReference, trimGraphQLErrors } from '../../../lib/utils';
import Constants from '../../../lib/constants';
import { logEvent } from '../../../lib/GAHelper';
import { NEW_LOAN_ROUTES } from '../../../containers/LoanApplication/routes';
import { UPLOAD_SUPPORTING_DOCUMENT } from '../../../hooks/useDocumentUploader';
import { UPLOAD_IMAGE } from '../../../hooks/useImageUploader';

const FILE_TYPES = {
  WORK_ID: 'WORKID',
  SELFIE: 'PASSPORT',
  GOVERNMENT_ID: 'GOVERNMENTID',
  DOCUMENT: 'DOCUMENT'
};

/* eslint-disable */
const CustomerInfo = ({ history }) => {
  const {
    updateContextState,
    clientInfo,
    clientInfoLoading,
    updateAuthType,
    signupLoading,
    signupState,
    setSignupState,
    viewerLoading,
    viewerData,
    viewerError,
  } = useSignupContext();
  
  // Add state for upload errors
  const [uploadError, setUploadError] = useState("");

  const user = viewerData?.loaded?.viewer?.me;
  const account = viewerData?.loaded?.viewer?.account;
  const applications = account?.applications?.nodes;
  const applicationCount = applications?.length || 0;


  const [updateCustomerInfoMutation, { loading, error }] = useMutation(
    UPDATE_CUSTOMER_INFO,
    {
      onCompleted: () => {
        const payStackMetadata = {
          accountId: signupState?.account?.id,
          cardReference: generateCardReference(),
          transactionType: Constants.transactType.transactionType,
        };

        setSignupState((prevState) => ({
          ...prevState,
          payStackMeta: payStackMetadata,
          phoneNumber: signupState?.user?.bvnStatus?.phone || user?.bvnStatus?.phone,
        }));

        updateContextState('loan-product', {
          payStackMeta: payStackMetadata,
        });
        updateAuthType();
        history.push(`/application/${NEW_LOAN_ROUTES.newApplication}`);
      },
    }
  );

  const [mutateImage, { loading: uploadImageLoading, error: uploadImageError }] = useMutation(
    UPLOAD_IMAGE,
  );

  const [mutateFile, { loading: uploadDocumentLoading, error: uploadSupportingDocError }] = useMutation(
    UPLOAD_SUPPORTING_DOCUMENT,
  );

  const showErrorAlert = useCallback((message) => {
    setUploadError(message);
  }, []);

  const uploadFile = useCallback(async (fileDetails, options) => {
    const { type, name, documentName, documentNumber } = options;
    const { file, validity } = fileDetails;
    
    if (!validity) {
      throw new Error('Invalid file');
    }
    
    const { id } = user || {};
    let fileName;
    let variables;

    const firstName = user?.firstName || '';
    const lastName = user?.lastName || '';
    
    const isImage = file?.type?.startsWith('image/');
    
    switch (type) {
      case FILE_TYPES.WORK_ID:
        fileName = `work_id_${firstName}_${lastName}_${id}`;
        variables = { image: file, fileName, type: FILE_TYPES.WORK_ID };
        break;
      case FILE_TYPES.SELFIE:
        fileName = name === 'selfie' 
          ? `selfie_${firstName}_${lastName}_${id}`
          : `image_${firstName}_${lastName}_${id}`;
        variables = { image: file, fileName, type: FILE_TYPES.SELFIE };
        break;
      case FILE_TYPES.GOVERNMENT_ID:
        fileName = `${documentName}_${documentNumber}_${firstName}_${lastName}_${id}`;
        variables = { image: file, fileName, type: FILE_TYPES.GOVERNMENT_ID };
        break;
      case FILE_TYPES.DOCUMENT:
        fileName = `${name}_${firstName}_${lastName}_${id}`;
        // If it's an image file but in a document field, use the image mutation
        if (isImage) {
          variables = { image: file, fileName, type: FILE_TYPES.SELFIE };
        } else {
          variables = { file, documentName: fileName, userId: id };
        }
        break;
      default:
        fileName = `${name}_${firstName}_${lastName}_${id}`;
        variables = { file, documentName: fileName, userId: id };
        break;
    }
    
    variables = {
      ...variables,
      fileName: variables?.fileName?.replace(/\s+/g, ''),
      documentName: variables?.documentName?.replace(/\s+/g, ''),
    };

    try {
      // Use appropriate mutation based on file type and mime type
      if ([FILE_TYPES.WORK_ID, FILE_TYPES.SELFIE, FILE_TYPES.GOVERNMENT_ID].includes(type) || 
          (type === FILE_TYPES.DOCUMENT && isImage)) {
        const { data } = await mutateImage({ variables });
        const { fileUrl, key, bucket } = data?.uploadImageAndSaveToUserMetaData || {};
        return { url: fileUrl, key, bucket };
      } else {
        const { data } = await mutateFile({ variables });
        return { 
          url: data?.uploadSupportingDocument?.file?.url, 
          key: data?.uploadSupportingDocument?.file?.key, 
          bucket: data?.uploadSupportingDocument?.file?.bucket 
        };
      }
    } catch (error) {
      showErrorAlert(`Failed to upload ${name}: ${error?.message}`);
      throw error;
    }
  }, [user, mutateImage, mutateFile, showErrorAlert]);

  const processFile = useCallback(async (file) => {
    const { name, fileDetails, documentName, documentNumber } = file;
    
    let type;
    // Determine file type based on name and mime type
    if (name === 'workId') {
      type = FILE_TYPES.WORK_ID;
    } else if (name === 'selfie') {
      type = FILE_TYPES.SELFIE;
    } else if (name === 'governmentId') {
      type = FILE_TYPES.GOVERNMENT_ID;
    } else {
      // For other files, check if it's an image or document
      type = fileDetails?.file?.type?.startsWith('image/') 
        ? (name === 'image' ? FILE_TYPES.SELFIE : FILE_TYPES.DOCUMENT)
        : FILE_TYPES.DOCUMENT;
    }
    
    const uploadResult = await uploadFile(fileDetails, { 
      type, 
      name, 
      documentName, 
      documentNumber 
    });
    
    // Validate that we have required fields in the upload result
    if (!uploadResult || !uploadResult?.key || !uploadResult?.url) {
      throw new Error(`Upload for ${name} failed or returned incomplete data`);
    }
    
    // Format the result based on file type
    if (name === 'governmentId') {
      return {
        name,
        fileDetails: {
          ...uploadResult,
          documentName,
          documentNumber,
        },
      };
    } else {
      return {
        name,
        fileDetails: uploadResult,
      };
    }
  }, [uploadFile]);

  // Extract form data without files
  const extractFormDataWithoutFiles = useCallback((formData, formName) => {
    const keySet = new Set(formData[formName]?.files?.map(item => item.name));
    return Object.keys(formData[formName])
      .filter(key => !keySet.has(key))
      .reduce((result, key) => {
        result[key] = formData[formName][key];
        return result;
      }, {});
  }, []);

  const updateCustomerInfo = async (data) => {
    logEvent('Signup', 'Add Customer Info.');
    const name = 'Customer Information';
    setUploadError(""); // Clear previous errors at the start

    try {
      let uploadedFiles = []; // Initialize uploadedFiles

      if (data[name]?.files?.length > 0) {
        try {
          // Upload all files in parallel
          const uploadPromises = data[name].files.map(file => processFile(file));
          uploadedFiles = await Promise.all(uploadPromises); // Assign result here

          // Verify that all uploads were successful and have required data
          const hasFailedUploads = uploadedFiles.some(file =>
            !file?.fileDetails ||
            !file?.fileDetails?.key ||
            !file?.fileDetails?.url ||
            Object.keys(file?.fileDetails).length === 0
          );

          if (hasFailedUploads) {
            // Set error and STOP processing here
            setUploadError('One or more file uploads failed. Please check the files and try again.');
            return; // <-- Exit the function early
          }

          // If we reach here, all uploads were successful
          const formDataWithoutFiles = extractFormDataWithoutFiles(data, name);
          data[name] = {
            ...formDataWithoutFiles,
            files: uploadedFiles // Use the successful results
          };

        } catch (uploadError) {
           // Catch potential errors from Promise.all or processFile itself if they threw
           setUploadError(`File upload process failed: ${uploadError?.message}`);
           return; // <-- Exit the function early
        }
      } else {
         // Handle case where there are no files - extract non-file data
         const formDataWithoutFiles = extractFormDataWithoutFiles(data, name);
         data[name] = {
             ...formDataWithoutFiles,
             files: [] // Ensure files array exists even if empty
         };
      }

      // --- Only proceed if uploads were successful (or no files needed) ---

      setSignupState((prevState) => ({
        ...prevState,
        // Ensure data[name] exists before spreading
        data: data[name] ? { [name]: data[name] } : {},
      }));

      updateCustomerInfoMutation({
        variables: {
          input: {
            kycInformation: {
              // Ensure data[name] exists before accessing
              data: data[name] ? { [name]: data[name] } : {},
            },
          },
        },
      });

    } catch (error) {
      // Catch any other unexpected errors during state update or mutation setup
      setUploadError(`Failed to update customer information: ${error?.message}`);
    }
  };

  const handleSubmit = (data) => {
    updateCustomerInfo(data);
  };

  useEffect(() => { 
    if (applicationCount === 0 && user?.kycInformation) {
      history.push(`/application/${NEW_LOAN_ROUTES.newApplication}`);  
    }
  }, [user?.kycInformation, applicationCount, history]);

  return (
    <S.Wrapper>
      <DataCard loading={clientInfoLoading || loading || signupLoading || viewerLoading || uploadImageLoading || uploadDocumentLoading }>

        {(viewerError || error || uploadImageError || uploadSupportingDocError || uploadError) && (
          <Alert classes="error">
            {trimGraphQLErrors(viewerError || error || uploadImageError || uploadSupportingDocError) || uploadError || 'There was an error processing your request'}
          </Alert>
        )}

        {!clientInfoLoading && (
          <OriginateFormGenerator
            onSubmit={handleSubmit}
            theme={theme}
            step={0}
            tabs={[clientInfo?.kycConfiguration?.form]}
            defaultFormValues={''}
          >
            <S.Button type="submit">Proceed</S.Button>
          </OriginateFormGenerator>
        )}
      </DataCard>
    </S.Wrapper>
  );
};

export default CustomerInfo;
