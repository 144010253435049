import React, { useEffect, useState } from 'react';
import './_RepaymentCard.scss';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Popup } from '../index';
import { transparentize } from 'polished';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { useClientInfoContext } from '../../hooks';
import { NairaAmount } from '../Icon';

const StyledRepaymentCard = styled.section`
  h6 {
    color: ${props => props.theme.primaryColor};
  }

  button,
  .button {
    &.secondary {
      border-color: ${props => props.theme.primaryColor};
    }
  }

  .repayment-progress {
    background: ${props => transparentize(0.85, props.theme.secondaryColor)};

    .progress {
      background: ${props => props.theme.secondaryColor};
    }
  }

  .overlay {
    &-status {
      border-color: ${props => props.theme.secondaryColor};
      color: ${props => props.theme.secondaryColor};
      &.warning {
        border: 1px solid #FFC107;
        background: #FFF9E6;
        color: #FFC107;
        font-size: 18px;
        text-align: center;

        /* Big screens: single line */
        @media (min-width: 768px) {
          width: 95%;
          padding: 20px 20px;
        }
        
        /* Smaller screens: allow wrapping */
        @media (max-width: 767px) {
          width: 90%;
          white-space: normal;
          padding: 10px;
        }
      }
    }
  }
`;

const RepaymentCard = props => {
  const {
    clientInfo: { bankDetails, noCardImplementation },
  } = useClientInfoContext();
  const [showPopup, setShowPopup] = useState(false);
  const { application, refetch } = props;

  const handleShowPopup = () => {
    setShowPopup(!showPopup);
  };

  const activeFormTabs = application?.loanCategory?.products?.[0]?.applicationForm?.filter(form =>
    !form?.linkedToOption
  );

  const customApplicationForm = application?.customApplicationForm
  && JSON.parse(JSON.stringify(application?.customApplicationForm));

  const index = customApplicationForm?.step || 0;
  const outOfTabs = activeFormTabs?.length ? index >= activeFormTabs.length : false;

  const title = activeFormTabs?.[index]?.name;
  const progressWidth = {
    width: `${Math.abs(props.percent).toFixed(2)}%`,
  };

  const nextInstallment =
    (application &&
      application.repayments &&
      application.repayments.find(
        repayment => repayment.status.name === 'PENDING',
      )) ||
    {};

  const extractAttributes = () => {
    const result = {};
    const namesSet = new Set([
      "requiresBankStatement",
      "requiresBankStatementUpload",
      "allowCardSkipDuringApplication",
      "noCardImplementation",
      "requiresPhoneVerification",
    ]);

      const attributeData = application?.application ?? application
      
      attributeData?.loanCategory?.loanCategoryAttributes?.forEach(item => {
        const attrName = item?.attribute?.name;
        if (namesSet.has(attrName)) {
          const rawValue = item?.value;
          // Handle null values
          if (rawValue === null) {
            result[attrName] = null;
          } 
          // Handle boolean strings
          else if (rawValue === "true") {
            result[attrName] = true;
          } 
          else if (rawValue === "false") {
            result[attrName] = false;
          } 
          // Handle numeric strings
          else if (!isNaN(rawValue) && rawValue !== "") {
            result[attrName] = Number(rawValue);
          } 
          // Keep other values as is
          else {
            result[attrName] = rawValue;
          }
        }
      });
      
      return result;
    };
  
    const applicationAttributes = extractAttributes();

    const getOverlayMessage = () => {
      const errors = [];
      if (!application?.user?.isEmailConfirmed) {
        errors.push("Pending email verification. Please click on the Complete Your Application button below to proceed.");
      }
      if (!application?.user?.isPhoneConfirmed && applicationAttributes?.requiresPhoneVerification) {
        errors.push("Pending phone number verification. Please click on the Complete Your Application button below to proceed.");
      }
      if (!outOfTabs && title) {
        errors.push(`Pending completion of the ${title} form. Please click on the Complete Your Application button below to proceed.`);
      }
      
      if ((!application?.account?.cards || application.account.cards.length === 0) 
        && !applicationAttributes?.allowCardSkipDuringApplication) {
        errors.push("Pending addition of a debit card. Please click on the Complete Your Application button below to proceed.");
      }
      
      if (
        (!application?.account?.bankAccounts ||
          application.account.bankAccounts.length === 0) ||
        !application?.completedSteps?.includes("SET_APPLICATION_BANK_ACCOUNT")
      ) {
        errors.push("Pending addition of a bank account. Please click on the Complete Your Application button below to proceed.");
      }
      if (!application?.credit?.ticketNo && application?.requiredSteps?.includes("INITIATE_BANK_STATEMENT_REQUEST") && !application?.requiredSteps?.includes("UPLOAD_BANK_STATEMENT")) {
        errors.push("Pending completion of the MBS step. Please click on the Complete Your Application button below to proceed.");
      }
      if (!application?.hasPdfBankStatement && application?.requiredSteps?.includes("UPLOAD_BANK_STATEMENT")) {
        errors.push("Pending bank statement upload. Please click on the Complete Your Application button below to proceed.");
      }

      if (!application.completedSteps?.includes('COMPLETE_APPLICATION')) {
        errors.push("Pending completion of your application. Please click on the Complete Your Application button below to proceed. ");
      }
      
      if (
        application?.completedSteps?.includes('COMPLETE_APPLICATION') && 
        !application?.account?.cards?.length &&
        applicationAttributes?.allowCardSkipDuringApplication
      ) {
        errors.push("Pending addition of a debit card. Please go to payment options under the settings tab to add a debit card.")
      }
    
      if (!errors.length) {
        return application.completedSteps?.includes('COMPLETE_APPLICATION')
        ? "PROCESSING"
        : props.status;          
      }
      return errors[0];
    }; 


  const getCardOverlayMessage = () => {
    if (
      application?.application?.completedSteps?.includes('COMPLETE_APPLICATION') &&
      !application?.application?.account?.cards?.length &&
      applicationAttributes?.allowCardSkipDuringApplication && application?.application?.status?.name !== "PENDING"
    ) {
      return "Pending addition of a debit card. Please go to payment options under the settings tab to add a debit card.";
    }
    return null;
  };
  
  const cardOverlayMessage = getCardOverlayMessage();

  const overlayMessage = application?.status?.name === 'PENDING' ? getOverlayMessage() : props.status

  const isError = overlayMessage !== "PROCESSING" && overlayMessage !== props.status;

  useEffect(() => {
   refetch()
  }, [])
  
  return (
    <StyledRepaymentCard className={'repayment-card'}>
      <div className="left">
        <h6>Your Current Repayment</h6>
        <h1>
          <NairaAmount amount={props.amountPaid} /> <span>of</span>{' '}
          <NairaAmount amount={props.fullAmount} />
        </h1>
        <div className="repayment-progress">
          <span className="progress" style={progressWidth} />
        </div>
        <span className="text">
          {Math.abs(props.percent).toFixed(2)}% Complete
        </span>
      </div>
      <div className="right">
        <h6>Your Next Payment</h6>
        <h1>
          <NairaAmount
            amount={
              (nextInstallment && nextInstallment.outstandingPayment) ||
              props.repaymentAmount
            }
          />
        </h1>
        <span className="text">
          {props.repaymentDate === null
            ? 'N/A'
            : moment(props.repaymentDate).format('LL')}
        </span>
        {(props.status === 'DISBURSED' || props.status === 'OVERDUE') && (
          <div className="quick-repay">
            {noCardImplementation ? (
              ''
            ) : (
              <Button
                classes="small"
                click_event={() => props.history.push('/loans/repay-loan')}
                button_text="Make Repayment"
              />
            )}
          </div>
        )}
        {showPopup && (
          <Popup title="Bank Details" classes="loan-requirements">
            <p>You can make payment to the account below:</p>
            {bankDetails ? (
              <ul>
                <li>BANK NAME : {bankDetails.bank.name}</li>
                <li>ACCOUNT NUMBER :{bankDetails.accountNumber}</li>
                <li>ACCOUNT NAME : {bankDetails.accountName}</li>
              </ul>
            ) : (
              ''
            )}

            {props.portfolioNumber ? (
              <p>
                Use <span className="bold-text">{props.portfolioNumber}</span>{' '}
                as the narrative.
              </p>
            ) : (
              ''
            )}
            <div>
              <p className="bold-text">
                Note: After making the payment, please click continue repayment
                to upload your receipt for payment confirmation.
              </p>
            </div>
            <div className="button-list center-text">
              <Button
                classes="next mb-1"
                click_event={() => props.history.push('/loans/repay-offline')}
              >
                Continue Repayment
              </Button>
              <Button classes="secondary" click_event={handleShowPopup}>
                Cancel Repayment
              </Button>
            </div>
          </Popup>
        )}
      </div>
      {props.overlay && (
        <aside className="overlay">
          <div className={`overlay-status ${isError ? 'warning' : ''}`}>
            {overlayMessage}
          </div>
        </aside>
      )}

      {cardOverlayMessage && (
        <aside className="overlay">
          <div className={`overlay-status ${isError ? 'warning' : ''}`}>
            {cardOverlayMessage}
          </div>
        </aside>
      )}

    </StyledRepaymentCard>
  );
};

RepaymentCard.propTypes = {
  amountPaid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fullAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  percent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  repaymentAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  repaymentDate: PropTypes.any,
  status: PropTypes.any,
  overlay: PropTypes.bool,
  portfolioNumber: PropTypes.string,
};

export default withRouter(RepaymentCard);
