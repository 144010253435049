/* eslint-disable prettier/prettier */
import { useCallback, useEffect, useState } from "react";
import { useLoanApplicationContext } from "../../../hooks";
import {
  CONFIRM_PERSONAL_EMAIL,
  TRIGGER_PERSONAL_EMAIL_CONFIRMATION,
  UPDATE_USER_EMAIL,
} from "../mutations";
import { handleGraphQLErrors } from "../../../lib/utils";
import {  useMutation } from "@apollo/client";
import { pages } from "../../../lib/constants";
import { useHistory } from 'react-router';
import { NEW_LOAN_ROUTES } from '../routes';

const useEmailVerification = ({ customForm }) => {
  const {
    loanApplicationState,
    setLoanApplicationState,
    applicationLoading,
    applicationNumber,
    updateUserDetails,
    goToPreviousPage,
    goToNextPage,
    indexSlug,
    createApplicationTrace,
    clientInfo : {
      requiresPhoneVerification
    },
    setCompletedStep,
    loanApplicationIsLoading,
    user,
    userLoading,
  } = useLoanApplicationContext();
  const [emailError, setEmailError] = useState(null);
  const [otpResendSuccess, setOtpResendSuccess] = useState(null);

  const history = useHistory();

  const [alertSuccess, setAlertSuccess] = useState(null);
  const [editEmailPopup, setEditEmailPopup] = useState(false);
  const currentEmail=user?.email;
  const [email, setEmail] = useState(currentEmail);

  const getPath = (path) =>
    `/application-custom/${applicationNumber}/${path}`;

  const [confirmEmail, { loading: confirmEmailLoading }] = useMutation(
    CONFIRM_PERSONAL_EMAIL,
    {
      onError(error) {
        const errorMessage = handleGraphQLErrors(error);
        setEmailError(errorMessage || "Unable to confirm your email.");
        hideAlert(setEmailError);
      },
      onCompleted() {
          setCompletedStep("verifyEmail");

          if (customForm) {
            if (requiresPhoneVerification){
              history.push(getPath(NEW_LOAN_ROUTES.verifyPhone));
            } else {
              history.push(getPath());
            }
         } else {
           goToNextPage();
         }
      },
    }
  );

  const [triggerEmailConfirmation, { loading: emailConfirmationLoading }] =
    useMutation(TRIGGER_PERSONAL_EMAIL_CONFIRMATION, {
      onError(error) {
        const errorMessage = handleGraphQLErrors(error);
        setEmailError(
          errorMessage || "Unable to resend OTP. Please, try again later."
        );
        hideAlert(setEmailError);
      },
      onCompleted({ triggerPersonalEmailConfirmation }) {
        const { ok } = triggerPersonalEmailConfirmation;
        if (!ok) {
          setEmailError("Unable to send OTP.");
          hideAlert(setEmailError);
        } else {
          setOtpResendSuccess(`OTP sent to ${email}`);
          hideAlert(setOtpResendSuccess);
        }
      },
    });

  const [updateEmail, { loading: updateEmailLoading }] = useMutation(
    UPDATE_USER_EMAIL,
    {
      onError(error) {
        const errorMessage = handleGraphQLErrors(error);
        setEmailError(errorMessage || "Unable to update your email.");
        hideAlert(setEmailError);
      },

      onCompleted({ updateUserEmail: { ok } }) {
        if (!ok) {
          {
            setEmailError("Unable to update your email");
            hideAlert(setEmailError);
          }
        } else {
          setAlertSuccess("Email successfully changed");
          hideAlert(setAlertSuccess);
          resendEmailOTP();
        }
      },
    }
  );

  const hideAlert = (state) => {
    setTimeout(() => {
      state(null);
    }, 5000);
  };

  const handleSubmit = useCallback(async (values) => {
    await confirmEmail({
      variables: {
        code: values?.workEmailVerificationCode,
      },
    });
  }, []);

  const openEditButtonPopup = () => {
    setEditEmailPopup(true);
  };

  const closeEditButtonPopup = () => {
    setEditEmailPopup(false);
  };

  const resendEmailOTP = useCallback(async () => {
    await triggerEmailConfirmation();
  }, []);

  const handleEmailEdit = useCallback(async (values) => {
    setEmail(values.email);
   await updateEmail({ variables: { userId: user?.id, email: values?.email } });
    closeEditButtonPopup();
  }, [user]);

  useEffect(() => {
    if (currentEmail) {
      setEmail(currentEmail);
    }

    triggerEmailConfirmation();
  }, [user]);

  useEffect(() => {
    createApplicationTrace(
      pages.emailVerification,
      "Personal Email Verification Screen"
    );
  }, []);

  return {
    updateUserDetails,
    loanApplicationState,
    setLoanApplicationState,
    applicationLoading,
    goToNextPage,
    goToPreviousPage,
    userLoading,
    indexSlug,
    createApplicationTrace,
    otpResendSuccess,
    emailError,
    setOtpResendSuccess,
    email,
    editEmailPopup,
    setEditEmailPopup,
    confirmEmailLoading,
    emailConfirmationLoading,
    handleSubmit,
    handleEmailEdit,
    openEditButtonPopup,
    resendEmailOTP,
    alertSuccess,
    closeEditButtonPopup,
    loanApplicationIsLoading,
    updateEmailLoading,
  };
};

export default useEmailVerification;
